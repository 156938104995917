import * as React from 'react'
import { BanquetLoader } from 'banquet-runtime-modules'
import { ErrorPage404 } from '@toasttab/buffet-pui-error-pages'
import { IBanquetInitialData } from '@toasttab/do-secundo-cornucopia-types'

export function App(props: IBanquetInitialData) {
  // No routing for now as the main app is always present.
  switch (props.mode) {
    case 'OO':
      return <BanquetLoader name='takeout-web' />
    case 'OPT':
    case 'STP':
    case 'MNP':
    case 'TTS':
    case 'OPT_PREVIEW':
    case 'QR':
    case 'SHORTENED':
      return <BanquetLoader name='opt-web' />
    case 'CATERING':
      return <BanquetLoader name='corn-catering-oo-web' />
    default:
      return <ErrorPage404 />
  }
}
